export default function dateFilter(value, format = 'date') {
  let options = {};
  if (format.includes('date')) {
    options.year = 'numeric';
    options.month = '2-digit';
    options.day = '2-digit';
  }
  if (format.includes('time')) {
    options.hour = 'numeric';
    options.minute = '2-digit';
    options.second = '2-digit';
  }


  // options.hc = 'h24';
  try {
    let val = new Intl.DateTimeFormat('en-CA', options).format(new Date(value));
    return val;
  } catch (error) {
    let val = new Intl.DateTimeFormat('en-CA', options).format(new Date());
    return val;
  }

}
