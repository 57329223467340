<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
      v-if="!UID"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="white" v-bind="attrs" v-on="on" text outlined>
          LOGIN
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>LOGIN / REGISTER</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="login"> LOGIN </v-btn>
            <v-btn dark text @click="register"> REGISTER </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-9">
          <v-text-field
            label="EMAIL"
            v-model="email"
            single-line
          ></v-text-field>
          <v-text-field
            label="PASSWORD"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            single-line
            v-model="password"
          ></v-text-field>
          <v-text-field label="NAME" single-line v-model="name"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn v-else color="white" @click="logout" text outlined>
      <span v-if="userInfo">{{ userInfo.name }}</span> LOGOUT
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    showPassword: false,
    email: null,
    password: null,
    name: null,
  }),
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
  },
  methods: {
    async login() {
      await this.$store.dispatch("loading", true);
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.dialog = false;
        this.$store.dispatch("setGlobalMsg", "HELLO " + this.userInfo.name);
      } catch (error) {
        this.$store.dispatch("setError", "LOGIN ERROR");
      }

      await this.$store.dispatch("loading", false);
    },
    async register() {
      if (this.email && this.password && this.name) {
        if (this.email != "" && this.password != "" && this.name != "") {
          //   await this.$store.dispatch("loading", true);
          try {
            await this.$store.dispatch("loading", true);
            await this.$store.dispatch("registerUser", {
              email: this.email,
              password: this.password,
              name: this.name,
              admin: false,
              last_selected_project: "",
            });
            this.$store.dispatch("setGlobalMsg", "NEW USER CREATED");
            this.dialog = false;
            await this.$store.dispatch("loading", false);
          } catch (error) {
            this.$store.dispatch("clearError");
            this.$store.dispatch("setError", "REGISTER ERROR");
            await this.$store.dispatch("loading", false);
          }

          //   await this.$store.dispatch("loading", false);
          return;
        }
      }
      this.$store.dispatch("setError", "FILL ALL THE FIELDS");
    },
    async logout() {
      await this.$store.dispatch("loading", true);
      this.$store.dispatch("setGlobalMsg", "GOODBY " + this.userInfo.name);
      await this.$store.dispatch("logOut");
      await this.$store.dispatch("loading", false);
    },
  },
};
</script>

<style>
</style>