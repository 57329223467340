import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database'

import dateFilter from '@/filters/date-filter'
//filters
Vue.filter('date', dateFilter)

Vue.config.productionTip = false


const firebaseConfig = {
  apiKey: "AIzaSyAEzAGYYBpsHYNVIZ8Lwn3P6eN89Gc_b8M",
  authDomain: "hours-f2ea1.firebaseapp.com",
  databaseURL: "https://hours-f2ea1-default-rtdb.firebaseio.com",
  projectId: "hours-f2ea1",
  storageBucket: "hours-f2ea1.appspot.com",
  messagingSenderId: "600758548539",
  appId: "1:600758548539:web:df1e742b2b26731f45041e",
  measurementId: "G-CGH0DY2EVV"
};

var fire = firebase.initializeApp(firebaseConfig);

let app;
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
      beforeCreate() {
        // firebase.auth().onAuthStateChanged(user => {
        if (user) {
          console.log('logged in already');
          this.$store.dispatch("autoLogin", user)
        }
        // })
      },
    }).$mount('#app')
  }
})

export default fire;