import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/modules/user.js'
import userInfo from '@/store/modules/userInfo.js'
import common from '@/store/modules/common.js'
import rooms from '@/store/modules/rooms.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    userInfo,
    common,
    rooms
  }
})
