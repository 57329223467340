<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon
        class="d-lg-none"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <router-link
        class="d-lg-flex d-none"
        v-for="navigation in navigations"
        :key="navigation.name"
        :to="navigation.link"
        custom
        v-slot="{ navigate }"
      >
        <v-btn
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
          text
          x-large
          v-if="navigation_condition(navigation.condition)"
        >
          {{ navigation.name }}
        </v-btn>
      </router-link>
      <v-spacer></v-spacer>
      <loginModal v-if="!loading" />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary color="primary" dark>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item
            link
            v-for="navigation in navigations"
            :key="navigation.name"
            :to="navigation.link"
          >
            <v-list-item-title>{{ navigation.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
    <template v-if="error">
      <v-snackbar
        :value="true"
        :multi-line="true"
        :timeout="5000"
        @input="closeError"
      >
        {{ error }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="closeError">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <template v-if="globalMsg">
      <v-snackbar
        :value="true"
        :multi-line="true"
        :timeout="5000"
        @input="closeMsg"
      >
        {{ globalMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="green" text v-bind="attrs" @click="closeMsg">
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import loginModal from "@/components/modals/login_modal";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    group: null,
    navigations: [
      { name: "HOME", link: "/", condition: "userInfo" },
      { name: "CLOCK", link: "/clock", condition: "userInfo" },
      { name: "ALL PROJECTS", link: "/projects", condition: "admin" },
      { name: "ROOMS", link: "/rooms", condition: "userInfo" },
      { name: "ROOMS (NO SERVICE)", link: "/onePay", condition: "userInfo" },
      { name: "ToDo", link: "/todo", condition: "userInfo" },
      // { name: "PCB", link: "/pcb", condition: "userInfo" },
    ],
    //
  }),
  computed: {
    ...mapGetters(["UID", "loading", "userInfo"]),
    error() {
      return this.$store.getters.error;
    },
    globalMsg() {
      return this.$store.getters.globalMsg;
    },
  },
  components: {
    loginModal,
  },
  methods: {
    closeError() {
      this.$store.dispatch("clearError");
    },
    closeMsg() {
      this.$store.dispatch("clearGlobalMsg");
    },
    navigation_condition(condition) {
      if (condition === "userInfo") {
        return this.userInfo;
      }
      if (condition === "admin") {
        return this.userInfo && this.userInfo.admin;
      }
    },
  },
};
</script>
