// import firebase from 'firebase/compat/app';

export default {
  state: {
    ROOMS: null,
    PAYMENTS: null,
    masterCode: "",
    roomSearch: "",
  },
  mutations: {
    ROOMS(state, payload) {
      state.ROOMS = payload
    },
    masterCode(state, payload) {
      state.masterCode = payload
    },
    roomSearch(state, payload) {
      state.roomSearch = payload
    },
    PAYMENTS(state, payload) {
      state.PAYMENTS = payload
    },
    PAYED(state, payload) {
      state.ROOMS.forEach(element => {
        if (element.id === payload.roomIndex) {
          element.payed = payload.payed
        }
      });
    }
  },
  actions: {
    async GET_AllTableElements(context, payload) {
      // if (context.state[payload.var] === null) {
        let RoomPayments = await fetch(`https://editor.b2b.playflow.io/API/GET/TableByName.php?tableName=${payload.msg}`);
        RoomPayments = await RoomPayments.json();
        let content = RoomPayments.content;
        content = Object.keys(content).map(key => ({ ...content[key], id: key }))
        return content;
      // } else {
      //   return null
      // }
      // context.commit(payload.var, content);

    },
    async ADD_PAYMENT(context, payload) {
      let PAYMENT = await fetch(`https://editor.b2b.playflow.io/API/GET/INSERT.php?MSG=${payload.msg}`);
      console.log(PAYMENT);
    },
    PAYED(context, payload) {
      context.commit("PAYED", payload);
    },
    // async GET_AllTableElementsObjects(context, payload) {
    //   let RoomPayments = await fetch(`https://editor.b2b.playflow.io/API/GET/TableByName.php?tableName=${payload.msg}`);
    //   RoomPayments = await RoomPayments.json();
    //   let content = RoomPayments.content;
    //   // content = Object.keys(content).map(key => ({ ...content[key], id: key }))
    //   // context.commit(payload.var, content);
    //   return content;
    // },
  },
  getters: {
    ROOMS(state) {
      return state.ROOMS
    },
    masterCode(state) {
      return state.masterCode
    },
    roomSearch(state) {
      return state.roomSearch
    },
    PAYMENTS(state) {
      return state.PAYMENTS
    }
  },
}