// import firebase from 'firebase/compat/app';

export default {
    state: {
        loading: false,
        error: null,
        globalMsg: null
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
        setGlobalMsg(state, payload) {
            state.globalMsg = payload
        },
        clearGlobalMsg(state) {
            state.globalMsg = null
        },
    },
    actions: {
        loading (context, payload) {
            context.commit('setLoading',payload)
        },
        setError (context, payload) {
            context.commit('setError',payload)
        },
        clearError (context) {
            context.commit('clearError')
        },
        setGlobalMsg (context, payload) {
            context.commit('setGlobalMsg',payload)
        },
        clearGlobalMsg (context) {
            context.commit('clearGlobalMsg')
        },
    },
    getters: {
        loading(state) {
            return state.loading
        },
        error(state) {
            return state.error
        },
        globalMsg(state) {
            return state.globalMsg
        }
    }
}